// @ is an alias to /src
import Header from '@/components/sign/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
	name: 'findPwd',
	data: () => {
		return {
			activeBar: 0,
		}
	},
	components: {
		Header,
		Footer,
	}
}
